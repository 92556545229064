exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-awards-js": () => import("./../../../src/pages/awards.js" /* webpackChunkName: "component---src-pages-awards-js" */),
  "component---src-pages-guild-js": () => import("./../../../src/pages/guild.js" /* webpackChunkName: "component---src-pages-guild-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nomination-js": () => import("./../../../src/pages/nomination.js" /* webpackChunkName: "component---src-pages-nomination-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-tribe-js": () => import("./../../../src/pages/tribe.js" /* webpackChunkName: "component---src-pages-tribe-js" */)
}

